import { Empty, Icon, Link, List, Meta, Popover, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { environment } from '@Libs/constants/environment';

/**
 * Component for displaying and navigating to BiLira projects
 */
const Projects = () => {
  const { t } = useTranslation();
  const projects = [
    {
      name: t('navigation.projects.platform.title'),
      icon: <Icon size="lg" color="neutral-700" type="o:sparkles" />,
      description: t('navigation.projects.platform.desc'),
      link: environment.V1_WEB_URL,
    },
  ];

  return (
    <>
      <Popover
        py="2xl"
        position="left"
        button={
          <Text color="secondary-500" size="xl" weight="medium">
            {t('navigation.projects.kripto.title')}
          </Text>
        }
        customWidth="384"
      >
        {(close) => (
          <>
            <Text color="neutral-700" size="sm" weight="regular" mb="md">
              {t('navigation.projects.title')}
            </Text>
            <List
              empty={<Empty message={t('common.empty-message')} />}
              dataSource={projects}
              space="xs"
              renderItem={(record) => (
                <List.Item key={record.name} px="md" py="md" hoverBgColor="neutral-300">
                  <Link
                    anchor
                    href={record.link}
                    target="_blank"
                    onClick={() => {
                      close();
                    }}
                  >
                    <Meta
                      key={record.name}
                      position="left-top"
                      extra={record.icon}
                      title={
                        <Text color="secondary-500" weight="medium">
                          {record.name}
                        </Text>
                      }
                      description={
                        <Text size="xs" weight="regular">
                          {record.description}
                        </Text>
                      }
                    />
                  </Link>
                </List.Item>
              )}
            />
          </>
        )}
      </Popover>
    </>
  );
};

export default Projects;
