import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import {
  Block,
  Button,
  CircleTimer,
  Display,
  ICircleTimerReturn,
  notification,
  Panel,
  Spinner,
  withSuspense,
} from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import useSwapStateHook from '@Components/trade/buySell/hooks/useSwapStateHook';
import SwapQuery from '@Libs/clientInstances/swapQuery';
import { SWAP_RETRY_TIMES } from '@Libs/constants';
import useAuth from '@Libs/hooks/userAuth';
import { handleErrorResponse } from '@Libs/utils';

type SwapPreviewProps = {
  refetchCallback: () => void;
  onCancel: () => void;
};

/**
 * Component to display action buttons for swap preview
 */
function BuySellActions({ refetchCallback, onCancel }: SwapPreviewProps) {
  const { account } = useAuth();
  const { t } = useTranslation();
  const circleTimerRef = useRef<ICircleTimerReturn>();
  const refetchTryRef = useRef(1);
  const waitForComplete = useRef(false);
  const {
    changeSwapStatus,
    isOfferCanceled,
    isOfferTimeout,
    isOfferOpen,
    offerTtl,
    isOfferFetching,
    offerId,
    buySellType,
  } = useSwapStateHook();
  const [isTimerRunning, setIsTimerRunning] = useState(false);

  const { mutateAsync, isPending } = SwapQuery.usePatchSwapQuoteConfirm();

  const onFinish = useCallback(async () => {
    if (offerId) {
      circleTimerRef.current?.pause(true);
      waitForComplete.current = true;
      mutateAsync({ id: offerId, account: account }).catch((error) => {
        waitForComplete.current = false;

        changeSwapStatus('canceled');
        refetchTryRef.current = 3;
        handleErrorResponse(error);
      });
    } else {
      notification.error(t('market.swap.swap-offer-not-found'));
    }
  }, [mutateAsync, offerId, t]);

  // On unmount.
  useEffect(
    () => () => {
      refetchTryRef.current = 0;
      waitForComplete.current = false;
      circleTimerRef.current?.reset();
    },
    [],
  );

  useEffect(() => {
    if (isOfferCanceled) {
      waitForComplete.current = false;
      circleTimerRef.current?.reset();
    }
  }, [isOfferCanceled]);

  const refetchOffer = () => {
    waitForComplete.current = false;
    circleTimerRef.current?.reset();
    changeSwapStatus('requested');
    refetchCallback();
  };

  const onRefetchButtonClick = () => {
    refetchTryRef.current = 0;
    refetchOffer();
  };

  const onTimerComplete = useCallback(() => {
    setIsTimerRunning(false);
    refetchTryRef.current += 1;
    changeSwapStatus('initiated');

    if (refetchTryRef.current <= SWAP_RETRY_TIMES) {
      refetchOffer();
    } else {
      changeSwapStatus('canceled');
    }
  }, [refetchOffer, changeSwapStatus]);

  const buttonVariant = buySellType === 'buy' ? 'filled-success' : 'filled-danger';

  useEffect(() => {
    if (isOfferOpen && offerTtl > 0) {
      setIsTimerRunning(true);
    }
  }, [isOfferOpen, offerTtl]);

  return (
    <div>
      <Display show={(isOfferTimeout || isOfferCanceled) && !isTimerRunning}>
        <Button
          variant={buttonVariant}
          size="lg"
          stretch
          loading={isPending}
          shine={true}
          onClick={onRefetchButtonClick}
        >
          {t('trade.offer.labels.refetch-offer')}
        </Button>
      </Display>

      <Display show={isTimerRunning}>
        <Button
          variant={buttonVariant}
          size="lg"
          disabled={!isOfferOpen}
          endIcon={
            <>
              (
              <CircleTimer
                ref={circleTimerRef}
                updateInterval={1000}
                onComplete={onTimerComplete}
                duration={offerTtl}
                variant="countdown"
              />
              )
            </>
          }
          type="submit"
          stretch
          loading={waitForComplete.current} // In case the confirmation succeeds, but websocket delays. Solve it.
          shine={true}
          onClick={onFinish}
        >
          {t('common.accept')}
        </Button>
      </Display>

      <Display show={isOfferFetching && !isTimerRunning}>
        <Button variant={buttonVariant} size="lg" stretch loading={true}>
          {t('trade.offer.labels.waiting-offer')}
        </Button>
      </Display>
    </div>
  );
}

export default withSuspense(
  memo(BuySellActions),
  <Panel>
    <Block justify="center" items="center" my="8xl">
      <Spinner size="xl" color="primary" />
    </Block>
  </Panel>,
);
