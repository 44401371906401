import { IdpQueryKey, UserInfo } from '@bilira-org/react-utils';
import { Query, QueryKey } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { useGeneralSessionStore } from '@/store';
import { addTrackerUser } from '@Libs/tracker/helpers/addTrackerUser';

const handleQuerySuccess = (data: unknown, query: Query<unknown, unknown, unknown, QueryKey>) => {
  if (query.queryKey.includes(IdpQueryKey.USER_INFO) && data) {
    useGeneralSessionStore.getState().setUserInfo(data as UserInfo);
    addTrackerUser({ userInfo: data as UserInfo });
    Sentry.setUser({ id: (data as UserInfo).id || 'Unknown' });
  }
};

export default handleQuerySuccess;
